<template>
  <router-link
    class="nav-button"
    :class="{
      'is-muted': muted
    }"
  >
    <img
      :src="image"
      alt=""
      class="image"
      width="1920"
      height="1080"
    >
    <span class="title">{{ title }}</span>
  </router-link>
</template>

<script>
export default {
  name: 'CNavButton',

  props: {
    image: {
      type: String,
      default: 'https://source.unsplash.com/dgJT71cXlC4/1920x1080'
    },
    title: {
      type: String,
      required: true
    },
    muted: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-button {
  display: grid;
  grid-template-areas: "cover";

  font-weight: 700;
  text-decoration: none;
  line-height: 1.2;
  color: var(--color-blue-100);

  box-shadow:
    0 0.05em 0.25em rgb(0 0 0 / 0.5),
    0 0.1em 0.75em rgb(0 0 0 / 0.3);

  transition: box-shadow 0.2s;

  &:hover,
  &:focus {
    box-shadow:
      0 0 0 0.5em var(--color-blue-100),
      0 0.1em 1em 0.25em rgb(0 0 0 / 1);
  }

  &.is-active {
    box-shadow:
      0 0 0 0.5em var(--color-yellow-brand),
      0 0.1em 1em 0.25em rgb(0 0 0 / 1);
  }
}

.image {
  grid-area: cover;
  align-self: stretch;
  object-fit: cover;

  transition: filter 0.4s;

  .is-muted & {
    filter: sepia(1) grayscale(0.7) contrast(0.6);
  }

  .is-active & {
    filter: none;
  }
}

.title {
  align-self: end;

  display: flex;
  align-items: flex-end;
  grid-area: cover;
  padding: 0.3em 0.5em;
  z-index: 1;

  overflow-wrap: break-word;
  word-break: break-word;
  hyphens: auto;

  background: var(--color-blue-brand);
}
</style>
