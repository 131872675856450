<template>
  <div class="youtube">
    <div ref="video" />
  </div>
</template>

<script>
// Third-party
import Player from 'youtube-player'

export default {
  name: 'CVideoYouTube',

  props: {
    id: {
      type: String,
      required: true
    }
  },

  emits: ['ended'],

  data () {
    return {
      player: null
    }
  },

  mounted () {
    this.player = Player(this.$refs.video, {
      host: 'https://www.youtube-nocookie.com',
      videoId: this.id,
      playerVars: {
        rel: 0,
        modestbranding: 1
      }
    })

    this.player.on('stateChange', event => {
      if (event.data === 0) {
        this.$emit('ended')
      }
    })
  },

  methods: {
    play () {
      this.player.playVideo()
    },
    pause () {
      this.player.pauseVideo()
    }
  }
}
</script>

<style lang="scss" scoped>
.youtube {
  ::v-deep(iframe) {
    height: 100%;
  }
}
</style>
