<template>
  <div class="content-media">
    <c-media-slider
      v-if="hasMedia"
      :items="media"
    />
  </div>
</template>

<script>
// Components
import CMediaSlider from '../components/CMediaSlider.vue'

// Globals
import { room } from '../store.js'

export default {
  name: 'VContent',

  components: {
    CMediaSlider
  },

  props: {
    roomID: {
      type: Number,
      required: true
    },
    topicID: {
      type: Number,
      required: true
    },
    contentID: {
      type: Number,
      required: true
    }
  },

  computed: {
    media () {
      return room.state.contents.get(this.contentID)
    },
    hasMedia () {
      return this.media && this.media.length
    }
  }
}
</script>

<style lang="scss" scoped>
.content-media {
  position: absolute;
  left: 3.9%;
  width: 92.2%;
  height: calc(100% - 7rem);
}
</style>
