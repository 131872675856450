<!--
  Make title focusable for afterEach hook in Vue Router. Re-focusing to the
  title ensures proper accessibility and doesn't leave a random element focused.
-->

<template>
  <h1
    class="title"
    tabindex="-1"
  >
    <slot />
  </h1>
</template>

<script>
export default {
  name: 'CTitle'
}
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;
  margin: 0;
  overflow: hidden;

  font-size: 2em;
  line-height: 1.2;
  white-space: nowrap;
  color: var(--color-mud-100);

  outline: 0;

  > * {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
