<template>
  <div class="topic">
    <c-topic-nav
      v-if="contents"
      :contents="contents"
    />
    <router-view v-slot="{ Component }">
      <transition name="content">
        <component
          :is="Component"
          :key="$route.path"
        />
      </transition>
    </router-view>
  </div>
</template>

<script>
// Components
import CTopicNav from '../components/CTopicNav.vue'

// Globals
import { room } from '../store.js'

export default {
  name: 'VTopic',

  components: {
    CTopicNav
  },

  props: {
    roomID: {
      type: Number,
      required: true
    },
    topicID: {
      type: Number,
      required: true
    }
  },

  computed: {
    contents () {
      return room.state.topics.get(this.topicID)?.contents
    }
  }
}
</script>

<style lang="scss" scoped>
.topic {
  height: 100%;
}

.content-enter-active,
.content-leave-active {
  transform-origin: top;

  transition: transform, opacity;
  transition-duration: var(--transition-duration-topic-nav);
}

.content-enter-from,
.content-leave-to {
  transform: scale(0.8);
  opacity: 0;
}
</style>
