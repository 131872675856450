<template>
  <c-container>
    <c-container-header>
      <c-title>
        <router-link
          :to="{
            name: 'room',
            params: { roomID }
          }"
          class="title-link"
        >
          {{ name }}
        </router-link>

        <template v-if="currentTopic">
          <c-icon
            type="chevron-right"
            class="title-icon"
            size="0.7"
          />

          <router-link
            :to="{
              name: 'topic',
              params: { roomID, topicID }
            }"
            class="title-link"
          >
            {{ currentTopic?.name }}
          </router-link>
        </template>
      </c-title>
    </c-container-header>

    <c-container-sidebar>
      <div class="room-sidebar">
        <c-nav-button
          v-for="([id, topic], index) in topics"
          :key="id"
          :ref="el => topicButtons[index] = el"
          :image="topic.cover"
          :title="topic.name"
          :to="{
            name: 'topic',
            params: { roomID, topicID: id }
          }"
          class="topic-button"
          muted
        />
      </div>
    </c-container-sidebar>

    <c-container-main>
      <router-view />
    </c-container-main>

    <c-external-link-overlay />
    <c-reset-modal />
  </c-container>
</template>

<script>
// Components
import CContainer from '../components/CContainer.vue'
import CContainerHeader from '../components/CContainerHeader.vue'
import CContainerMain from '../components/CContainerMain.vue'
import CContainerSidebar from '../components/CContainerSidebar.vue'
import CExternalLinkOverlay from '../components/CExternalLinkOverlay.vue'
import CIcon from '../components/CIcon.vue'
import CNavButton from '../components/CNavButton.vue'
import CResetModal from '../components/CResetModal.vue'
import CTitle from '../components/CTitle.vue'

// Functions
import { getRoomContent } from '../api.js'

// Globals
import { room } from '../store.js'

export default {
  name: 'VRoom',

  components: {
    CContainer,
    CContainerHeader,
    CContainerMain,
    CContainerSidebar,
    CExternalLinkOverlay,
    CIcon,
    CNavButton,
    CResetModal,
    CTitle
  },

  beforeRouteUpdate (to, from, next) {
    this.setTopicOffset(parseInt(to.params.topicID))
    next()
  },

  props: {
    roomID: {
      type: Number,
      required: true
    },
    topicID: {
      type: Number,
      default: undefined
    }
  },

  data () {
    return {
      topicButtons: []
    }
  },

  computed: {
    name () {
      return room.state.meta.name
    },
    topics () {
      return room.state.topics
    },
    currentTopic () {
      return room.state.topics.get(this.topicID)
    }
  },

  async created () {
    room.reset()
    const response = await getRoomContent(this.roomID)
    room.set(response)
  },

  methods: {
    setTopicOffset (topicID) {
      // The following slightly obscure contraption gets the position of the
      // button for the topic that has the same path as the argument.

      for (const button of this.topicButtons) {
        if (button.$attrs.to.params.topicID === topicID) {
          const offset = button.$el.getBoundingClientRect().y
          document.body.style.setProperty('--topic-button-position', offset)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.title-link {
  text-decoration: none;
  color: currentColor;
}

.title-icon {
  margin: 0 0.7em;

  color: var(--color-mud-900);

  background: var(--color-mud-400);
  border-radius: 50%;
}

.topic-button {
  position: relative;
  z-index: var(--z-index-topic-button);
}

.room-sidebar {
  display: grid;
  gap: var(--container-padding);

  font-size: 0.9em;
}
</style>
