<template>
  <div>
    <button
      class="button is-left"
      aria-label="Vorheriger Inhalt"
      @click="$emit('previous')"
    >
      <c-icon type="chevron-left" />
    </button>
    <button
      class="button is-right"
      aria-label="Nächster Inhalt"
      @click="$emit('next')"
    >
      <c-icon type="chevron-right" />
    </button>
  </div>
</template>

<script>
// Components
import CIcon from './CIcon.vue'

export default {
  name: 'CMediaSliderControls',

  components: {
    CIcon
  },

  emits: ['previous', 'next']
}
</script>

<style lang="scss" scoped>
.button {
  position: absolute;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1em;
  height: 1em;
  padding: 0;
  transform: translateY(-50%);

  font-size: 3.5em;
  color: var(--color-blue-100);

  background: var(--color-blue-brand);
  border: 0;
  border-radius: 50%;
  box-shadow:
    0 0.05em 0.25em rgb(0 0 0 / 0.5),
    0 0.1em 0.75em rgb(0 0 0 / 0.3);

  transition:
    color 0.2s,
    box-shadow 0.2s;

  &.is-left {
    left: 0;
  }

  &.is-right {
    right: 0;
  }

  &:hover,
  &:focus {
    box-shadow:
      0 0 0 0.1em var(--color-blue-100),
      0 0.1em 1em 0.25em rgb(0 0 0 / 0.3);
  }
}
</style>
