<template>
  <div class="container">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'CContainer'
}
</script>

<style lang="scss" scoped>
.container {
  display: grid;
  grid-template:
    var(--header-height) 1fr /
    1fr 5.51fr;
  grid-template-areas:
    "header  header"
    "sidebar main";
  height: 100vh;
}
</style>
