<!-- eslint-disable vue/no-v-html -->

<template>
  <div
    ref="content"
    class="media-slider-item-text"
    :class="`is-${type}`"
    v-html="content"
  />
</template>

<script>
// Globals
import eventBus from '../event-bus.js'

export default {
  name: 'CMediaSliderItemText',

  props: {
    type: {
      type: String,
      required: true,
      validator: value => [
        'standalone',
        'subtitle'
      ].includes(value)
    },
    content: {
      type: String,
      required: true
    }
  },

  mounted () {
    this.$refs.content.querySelectorAll('a').forEach(link => {
      link.addEventListener('click', event => {
        event.preventDefault()
        eventBus.emit('external-link-overlay', { open: true, url: link.href })
      })
    })
  }
}
</script>

<style lang="scss" scoped>
.media-slider-item-text {
  &.is-standalone {
    font-size: 1.3em;
    line-height: 1.5;
  }

  &.is-subtitle {
    font-size: 1.25em;
    line-height: 1.2;

    ::v-deep(*) {
      font-size: 1em;
    }
  }

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }

  ::v-deep(h2) {
    margin: 1em 0 0.25em;
    font-size: 1.375em;
  }

  ::v-deep(p) {
    margin-bottom: 1em;
  }

  ::v-deep(a) {
    color: var(--color-blue-brand);
  }

  ::v-deep(ul) {
    margin: 0 0 1em;
    padding-left: 0.9em;
  }

  ::v-deep(li) {
    margin-bottom: 0.5em;
  }
}
</style>
