<template>
  <div>
    <input
      :id="id"
      class="checkbox"
      type="checkbox"
      :checked="modelValue"
      @input="$emit('update:modelValue', $event.target.checked)"
    >
    <label
      class="label"
      :for="id"
    >
      <c-icon
        :type="'checkbox-' + (modelValue ? 'checked' : 'blank')"
        size="1.5"
        class="switch"
      />
      <span class="text">{{ label }}</span>
    </label>
  </div>
</template>

<script>
import CIcon from './CIcon.vue'

export default {
  name: 'CFormSwitch',

  components: {
    CIcon
  },

  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    modelValue: {
      type: Boolean
    }
  },

  emits: ['update:modelValue']
}
</script>

<style lang="scss" scoped>
.checkbox {
  appearance: none;
  position: absolute;
}

.switch {
  margin: -0.13em;
}

.label {
  display: inline-flex;
  align-items: center;

  font-size: 1.25em;
  line-height: 1.2;

  .checkbox:focus + & {
    outline: 0.3em solid var(--color-blue-100);
    outline-offset: 0.3em;
  }
}

.text {
  margin-left: 0.8em;
}
</style>
