<template>
  <c-container>
    <c-container-header>
      <c-title>Raum wählen</c-title>
    </c-container-header>

    <c-container-sidebar />

    <c-container-main class="lobby-main">
      <div class="lobby-nav">
        <c-nav-button
          v-for="room in rooms"
          :key="room.id"
          :image="room.cover"
          :title="room.name"
          :to="{
            name: 'room',
            params: { roomID: room.id }
          }"
        />
      </div>
    </c-container-main>
  </c-container>
</template>

<script>
// Components
import CContainer from '../components/CContainer.vue'
import CContainerHeader from '../components/CContainerHeader.vue'
import CContainerMain from '../components/CContainerMain.vue'
import CContainerSidebar from '../components/CContainerSidebar.vue'
import CNavButton from '../components/CNavButton.vue'
import CTitle from '../components/CTitle.vue'

// Functions
import { convertMetaData } from '../functions/converter.js'
import { getRoomList } from '../api.js'

// Globals
import { settings } from '../store.js'

export default {
  name: 'VLobby',

  components: {
    CContainer,
    CContainerHeader,
    CContainerMain,
    CContainerSidebar,
    CNavButton,
    CTitle
  },

  data () {
    return {
      rooms: []
    }
  },

  computed: {
    linkToLobby: {
      get () {
        return settings.state.linkToLobby
      },
      set (value) {
        settings.set({ linkToLobby: value })
      }
    }
  },

  async created () {
    const response = await getRoomList()
    this.rooms = response.map(convertMetaData)
  }
}
</script>

<style lang="scss" scoped>
.lobby-main {
  overflow-y: auto;
}

.lobby-nav {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.6em;

  // Align with topic nav in main view.
  padding: 1.3em 2.8em;

  font-size: 1.5em;
}
</style>
