// Third-party
import { createRouter, createWebHistory } from 'vue-router'

// Views
import VContent from './views/VContent.vue'
import VLobby from './views/VLobby.vue'
import VRoom from './views/VRoom.vue'
import VRoomMedia from './views/VRoomMedia.vue'
import VSettings from './views/VSettings.vue'
import VTopic from './views/VTopic.vue'

// Globals
import eventBus from './event-bus.js'

/**
 * Parse IDs from route parameters
 * @param {import('vue-router').RouteLocation} route Vue Router route object
 * @returns {Object<string, number>} Parsed IDs
 */
function parseIDParams (route) {
  const props = {}

  for (const param in route.params) {
    props[param] = parseInt(route.params[param])
  }

  return props
}

const routes = [
  {
    path: '/',
    name: 'lobby',
    component: VLobby
  },
  {
    path: '/einstellungen',
    name: 'settings',
    component: VSettings
  },
  {
    path: '/:roomID(\\d+)',
    component: VRoom,
    props: route => parseIDParams(route),
    children: [
      {
        path: '',
        name: 'room',
        component: VRoomMedia
      },
      {
        path: ':topicID',
        name: 'topic',
        component: VTopic,
        props: route => parseIDParams(route),
        children: [
          {
            path: ':contentID',
            name: 'content',
            component: VContent,
            props: route => parseIDParams(route)
          }
        ]
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'lobby' }
  }
]

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'is-active',
  routes
})

router.beforeEach((to, from, next) => {
  eventBus.emit('external-link-overlay', { open: false })
  next()
})

router.afterEach(() => {
  // Move focus to the page title for proper accessibility and to ensure no
  // random element on the screen has focus.
  document.querySelector('h1')?.focus()
})

export default router
