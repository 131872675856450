<template>
  <main class="container-main">
    <slot />
  </main>
</template>

<script>
export default {
  name: 'CContainerMain'
}
</script>

<style lang="scss" scoped>
.container-main {
  position: relative;
  grid-area: main;
  margin: var(--container-padding);

  // Prevent slider overflowing the content.
  min-width: 0;
  min-height: 0;
}
</style>
