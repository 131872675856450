<template>
  <aside class="container-sidebar">
    <slot />
  </aside>
</template>

<script>
export default {
  name: 'CContainerSidebar'
}
</script>

<style lang="scss" scoped>
.container-sidebar {
  grid-area: sidebar;
  padding: var(--container-padding);
  overflow-y: auto;

  background: var(--color-mud-800);
}
</style>
