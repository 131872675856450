<template>
  <p class="media-slider-item-copyright">
    {{ text }}
  </p>
</template>

<script>
export default {
  name: 'CMediaSliderItemCopyright',

  props: {
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.media-slider-item-copyright {
  padding: 0.25em 0.5em;
  font-size: 0.9em;
  background: var(--color-mud-100);
  opacity: 0.8;
}
</style>
