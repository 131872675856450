<template>
  <svg
    class="icon"
    :class="{
      'is-left': left,
      'is-right': right
    }"
    viewBox="0 0 24 24"
    :style="{
      'font-size': `${size}em`
    }"
  >
    <path :d="path[type]" />
  </svg>
</template>

<script>
import {
  mdiArrowRight,
  mdiChevronLeft,
  mdiChevronRight,
  mdiClose,
  mdiLoading,
  mdiMapMarkerCheck,
  mdiCheckboxBlankOutline,
  mdiCheckBoxOutline
} from '@mdi/js'

const paths = {
  'arrow-right': mdiArrowRight,
  'checkbox-blank': mdiCheckboxBlankOutline,
  'checkbox-checked': mdiCheckBoxOutline,
  'chevron-left': mdiChevronLeft,
  'chevron-right': mdiChevronRight,
  'on-site': mdiMapMarkerCheck,
  close: mdiClose,
  loading: mdiLoading
}

export default {
  name: 'CIcon',

  props: {
    type: {
      type: String,
      required: true,
      validator: value => Object.keys(paths).includes(value)
    },
    size: {
      type: [Number, String],
      default: 1
    },
    left: {
      type: Boolean
    },
    right: {
      type: Boolean
    }
  },

  computed: {
    path: () => paths
  }
}
</script>

<style lang="scss" scoped>
.icon {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  vertical-align: middle;

  fill: currentColor;

  &.is-left {
    margin-right: 0.5em;
  }

  &.is-right {
    margin-left: 0.5em;
  }
}
</style>
