<template>
  <article class="glide__slide media-item">
    <c-media-slider-item-on-site
      v-if="onSite"
      class="on-site"
    />

    <template v-if="isLarge">
      <img
        v-if="image"
        :src="image"
        alt=""
        class="image-large"
        width="1375"
        height="774"
      >

      <c-video
        v-if="video"
        :id="video.id"
        ref="video"
        :type="video.type"
        class="video-large"
        @ended="$emit('video-played')"
      />

      <c-media-slider-item-copyright
        v-if="copyright"
        class="copyright-large"
        :text="copyright"
      />
    </template>

    <div
      v-else
      class="body text"
    >
      <div class="media-small">
        <img
          v-if="image"
          :src="image"
          alt=""
          width="500"
          height="282"
        >

        <c-video
          v-if="video"
          :id="video.id"
          ref="video"
          :type="video.type"
          class="video-small"
          @ended="$emit('video-played')"
        />

        <c-media-slider-item-copyright
          v-if="copyright"
          class="copyright-small"
          :text="copyright"
        />
      </div>

      <c-media-slider-item-text
        v-if="body"
        type="standalone"
        :content="body"
      />
    </div>

    <footer class="footer text">
      <c-media-slider-item-index
        class="footer-index"
        :current="index + 1"
        :total="total"
      />

      <c-media-slider-item-text
        v-if="caption"
        class="footer-text"
        type="subtitle"
        :content="caption"
      />

      <c-media-slider-link
        v-if="link"
        class="footer-link"
        :to="link"
      >
        {{ linkText }}
      </c-media-slider-link>
    </footer>
  </article>
</template>

<script>
// Components
import CMediaSliderItemCopyright from './CMediaSliderItemCopyright.vue'
import CMediaSliderItemIndex from './CMediaSliderItemIndex.vue'
import CMediaSliderItemOnSite from './CMediaSliderItemOnSite.vue'
import CMediaSliderItemText from './CMediaSliderItemText.vue'
import CMediaSliderLink from './CMediaSliderLink.vue'
import CVideo from './CVideo.vue'

export default {
  name: 'CMediaSliderItem',

  components: {
    CMediaSliderItemCopyright,
    CMediaSliderItemIndex,
    CMediaSliderItemOnSite,
    CMediaSliderItemText,
    CMediaSliderLink,
    CVideo
  },

  props: {
    index: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    image: {
      type: String,
      default: undefined
    },
    video: {
      type: Object,
      default: undefined
    },
    copyright: {
      type: String,
      default: undefined
    },
    caption: {
      type: String,
      default: undefined
    },
    body: {
      type: String,
      default: undefined
    },
    onSite: {
      type: Boolean
    },
    isLarge: {
      type: Boolean
    },
    link: {
      type: String,
      default: undefined
    },
    linkText: {
      type: String,
      default: undefined
    }
  },

  emits: ['video-played'],

  methods: {
    playVideo () {
      this.$refs.video.play()
    },
    pauseVideo () {
      this.$refs.video.pause()
    }
  }
}
</script>

<style lang="scss" scoped>
.media-item {
  display: grid;
  grid-template-rows: 1fr min-content;
  grid-template-areas: "media" "footer";

  color: var(--color-mud-900);

  background: var(--color-mud-100);
}

.body {
  grid-area: media;
  overflow-y: auto;

  border-bottom: 0.5em solid var(--color-mud-700);
}

.on-site {
  grid-area: media;
  justify-self: end;
  align-self: start;
  z-index: 1;
}

.text {
  padding: 1.25em 1.5em;
}

.image-large {
  grid-area: media;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: top;
}

.video-large {
  grid-area: media;
  width: 100%;
  height: calc(100% - 1.7em);
}

.video-small {
  height: 14.6em;
}

.media-small {
  position: relative;
  float: right;
  width: 26em;
  margin: 0 0 2em 2em;
}

.copyright-large {
  grid-area: media;
  justify-self: end;
  align-self: end;
}

.copyright-small {
  position: absolute;
  right: 0;
  bottom: 0;
}

.footer {
  grid-area: footer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-index {
  align-self: flex-end;
}

.footer-text {
  flex-grow: 1;
}

.footer-link {
  flex-shrink: 0;
  margin-left: 1.5em;
}
</style>
