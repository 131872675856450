// Third-party
import { reactive } from 'vue'

// Functions
import { convertMedia, convertMetaData } from './functions/converter.js'

// Settings
import { STORAGE_KEY_SETTINGS } from './settings.js'

export const settings = {
  state: reactive({
    linkToLobby: false,
    ...JSON.parse(localStorage.getItem(STORAGE_KEY_SETTINGS))
  }),

  set (data) {
    for (const [key, value] of Object.entries(data)) {
      this.state[key] = value
    }

    localStorage.setItem(STORAGE_KEY_SETTINGS, JSON.stringify(this.state))
  }
}

export const room = {
  state: reactive({
    meta: {},
    topics: new Map(),
    contents: new Map()
  }),

  set (data) {
    this.state.meta = convertMetaData(data)

    data.topics.forEach(topic => {
      const convertedTopic = convertMetaData(topic)
      convertedTopic.contents = topic.topics.map(convertMetaData)
      this.state.topics.set(topic.uid, convertedTopic)

      topic.topics.forEach(content => {
        this.state.contents.set(content.uid, content.media.map(convertMedia))
      })
    })
  },

  reset () {
    this.state.meta = {}
    this.state.topics = new Map()
    this.state.contents = new Map()
  }
}
