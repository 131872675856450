<template>
  <section
    v-show="modalVisible"
    class="reset-modal"
  >
    <c-global-events
      @click="handleInteraction"
      @touchstart="handleInteraction"
    />
    <div class="content">
      <p>Das Terminal wird zurückgesetzt in:</p>

      <p class="countdown">
        {{ modalCountdown / 1000 }}
      </p>

      <c-button @click="hideModal">
        Abbrechen
      </c-button>
    </div>
  </section>
</template>

<script>
// Third-party
import { GlobalEvents as CGlobalEvents } from 'vue-global-events'

// Components
import CButton from './CButton.vue'

// Globals
import { RESET_HIDDEN_TIMEOUT, RESET_MODAL_TIMEOUT } from '../settings.js'
import { settings } from '../store.js'

export default {
  name: 'CResetModal',

  components: {
    CButton,
    CGlobalEvents
  },

  data () {
    return {
      hiddenTimeout: null,
      modalInterval: null,
      modalVisible: false,
      modalCountdown: RESET_MODAL_TIMEOUT
    }
  },

  methods: {
    startHidden () {
      // Remove previous timeout if the reset time has been extended.
      clearTimeout(this.hiddenTimeout)

      this.hiddenTimeout = setTimeout(() => {
        this.startModal()
      }, RESET_HIDDEN_TIMEOUT)
    },
    startModal () {
      this.modalVisible = true

      this.modalInterval = setInterval(() => {
        this.modalCountdown -= 1000

        if (this.modalCountdown === 0) {
          const linkToLobby = settings.state.linkToLobby
          this.hideModal()
          this.$router.push({ name: linkToLobby ? 'lobby' : 'room' })
          window.location.reload()
        }
      }, 1000)
    },
    hideModal () {
      clearInterval(this.modalInterval)
      this.modalVisible = false
      this.modalCountdown = RESET_MODAL_TIMEOUT
    },
    handleInteraction () {
      this.hideModal()
      this.startHidden()
    }
  }
}
</script>

<style lang="scss" scoped>
.reset-modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: var(--z-index-reset-modal);

  color: var(--color-mud-900);

  background: rgb(0 0 0 / 0.6);
  backdrop-filter: blur(2vw);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  padding: var(--container-padding);

  background: var(--color-mud-100);
  box-shadow:
    0 0.01vw 0.1vw rgb(0 0 0 / 0.5),
    0 0.5vw 2vw rgb(0 0 0 / 0.3);
}

.countdown {
  margin: 0.5em 0;
  font-size: 1.5em;
}

</style>
