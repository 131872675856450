<template>
  <p class="media-slider-item-on-site">
    <c-icon
      type="on-site"
      size="1.25"
      left
    />
    Vor Ort
  </p>
</template>

<script>
import CIcon from './CIcon.vue'

export default {
  name: 'CMediaSliderItemOnSite',

  components: {
    CIcon
  }
}
</script>

<style lang="scss" scoped>
.on-site {
  display: inline-flex;
  align-items: center;
  padding: 0.7em 0.8em;

  font-weight: 700;
  line-height: 1;
  color: var(--color-green-100);

  background: var(--color-green-600);
  box-shadow:
    0 0 0.2em rgb(0 0 0 / 0.1),
    0 0.1em 0.4em rgb(0 0 0 / 0.3);
}
</style>
