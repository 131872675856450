// Third-party
import { createApp } from 'vue'

// Entrypoint
import App from './App.vue'

// Plugins
import router from './router.js'

// CSS
import '@glidejs/glide/src/assets/sass/glide.core.scss'
import './main.scss'

createApp(App)
  .use(router)
  .mount('#app')
