<template>
  <component
    :is="tag"
    class="button"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: 'CButton',

  props: {
    tag: {
      type: String,
      default: 'button',
      validator: value => [
        'button',
        'router-link'
      ].includes(value)
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  display: inline-flex;
  align-items: center;
  padding: 0.6em 0.8em;

  font-size: 1.25em;
  text-decoration: none;
  line-height: 1;
  color: var(--color-blue-100);

  background: var(--color-blue-brand);
  border: 0;
  box-shadow:
    0 0.05em 0.2em rgb(0 0 0 / 0.4),
    0 0.1em 0.75em rgb(0 0 0 / 0.2);
}
</style>
