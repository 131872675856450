<template>
  <transition name="external-link-overlay">
    <section
      v-if="open"
      class="external-link-overlay"
    >
      <button
        aria-label="Schließen"
        class="close"
        @click="open = false"
      >
        <c-icon type="close" />
      </button>

      <c-icon
        v-if="isLoading"
        type="loading"
        class="loading"
      />

      <iframe
        :src="url"
        class="page"
        :class="{
          'is-loading': isLoading
        }"
        sandbox="allow-scripts"
        @load="onLoad"
      />
    </section>
  </transition>
</template>

<script>
// Components
import CIcon from './CIcon.vue'

// Globals
import eventBus from '../event-bus.js'

export default {
  name: 'CExternalLinkOverlay',

  components: {
    CIcon
  },

  data () {
    return {
      open: false,
      url: null,
      isLoading: false
    }
  },

  created () {
    eventBus.on('external-link-overlay', event => {
      if (event.open) {
        this.url = event.url
        this.open = true
        this.isLoading = true
      } else {
        this.open = false
      }
    })
  },

  methods: {
    onLoad () {
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.external-link-overlay {
  position: relative;
  grid-area: main;
  z-index: var(--z-index-external-link-overlay);

  background: var(--color-mud-900);
}

.external-link-overlay-enter-active,
.external-link-overlay-leave-active {
  transform-origin: center 10em;

  transition:
    transform 0.4s,
    opacity 0.4s;
}

.external-link-overlay-enter-from,
.external-link-overlay-leave-to {
  transform: scale(0.8);
  opacity: 0;
}

.close {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0.3em;
  right: 0.3em;
  width: 1.5em;
  height: 1.5em;
  padding: 0;
  z-index: 1;

  font-size: 2em;
  color: var(--color-red-100);

  background: var(--color-red-600);
  border: 0;
  border-radius: 50%;
  box-shadow:
    0 0.05em 0.25em rgb(0 0 0 / 0.5),
    0 0.1em 0.75em rgb(0 0 0 / 0.3);
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  font-size: 4em;
  color: var(--color-mud-100);

  animation: rotate linear infinite 1s;
}

.page {
  display: block;
  height: 100%;
  background: #fff;

  transition: opacity 0.5s;

  &.is-loading {
    overflow: hidden;
    opacity: 0.5;
  }
}

@keyframes rotate {
  to {
    transform: translate(-50%, -50%) rotate(1turn);
  }
}
</style>
