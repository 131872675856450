<template>
  <p class="media-slider-item-index">
    {{ current }}
    <span class="divider">/</span>
    {{ total }}
  </p>
</template>

<script>
export default {
  name: 'CMediaSliderItemIndex',

  props: {
    current: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.media-slider-item-index {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 3em;
  height: 3em;
  margin-right: 1.5em;

  font-weight: 700;
  line-height: 0;
  color: var(--color-mud-900);

  background: var(--color-mud-400);
  border-radius: 50%;
}

.divider {
  color: var(--color-mud-500);
}
</style>
