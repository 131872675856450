import { API_BASE } from '../settings.js'

/**
 * Room list object.
 * @typedef {Object} RoomListItem
 * @property {number} uid ID
 * @property {string} slug Slug
 * @property {string} name Name
 * @property {Image} [cover] Cover image
 */

/**
 * Room object.
 * @typedef {Object} Room
 * @property {number} uid ID
 * @property {string} slug Slug
 * @property {string} name Name
 * @property {Media[]} [media] Media objects
 * @property {Room[]} [topics] Child topics
 * @property {Image} [cover] Cover image
 */

/**
 * Media object.
 * @typedef {Object} Media
 * @property {number} uid ID
 * @property {string} caption Caption
 * @property {string} bodytext Body
 * @property {string} link Link URL
 * @property {string} [link_text] Link text
 * @property {0|1} on_site On site flag
 * @property {0|1} large_image Large image flag
 * @property {Image} [image] The actual media thing.
 */

/**
 * Image or video object.
 * @typedef {Object} Image
 * @property {number} uid ID
 * @property {'image'|'video'} type Type
 * @property {string} mime_type Mime type
 * @property {string} copyright Copyright notice
 * @property {0|1} autoplay Autoplay video
 * @property {string} __uri URL
 */

/**
 * Convert meta data
 * @param {Room} data Meta data
 */
export function convertMetaData (data) {
  return {
    id: data.uid,
    slug: data.slug,
    name: data.name,
    cover: createImageLink(data.cover),
    media: data.media?.map(convertMedia)
  }
}

/**
 * Convert media data
 * @param {Media} media Media data
 */
export function convertMedia (media) {
  return {
    id: media.uid,
    caption: media.caption,
    body: media.bodytext,
    copyright: media.image?.copyright,
    link: media.link,
    linkText: media.link_text,
    onSite: !!media.on_site,
    isLarge: !!media.large_image,
    autoplay: !!media.image?.autoplay,
    image: createImageLink(media.image),
    video: createVideo(media.image)
  }
}

/**
 * Convert image data
 * @param {Image} [image] Image data
 */
function createImageLink (image) {
  if (image?.type === 'image') {
    return `${API_BASE}/image?uid=${image.uid}&w=1400`
  }
}

/**
 * Extract YouTube video ID
 * @param {Image} [video] Video data
 */
function createVideo (video) {
  if (video?.mime_type === 'video/vimeo') {
    return {
      type: 'vimeo',
      id: video.__uri
    }
  }

  if (video?.mime_type === 'video/youtube') {
    const url = new URL(video.__uri)
    const params = new URLSearchParams(url.search)

    return {
      type: 'youtube',
      id: params.get('v')
    }
  }
}
