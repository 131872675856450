<template>
  <div
    class="media-slider"
    @click="setUserInteraction"
  >
    <div
      ref="slider"
      class="glide"
    >
      <div
        class="glide__track"
        data-glide-el="track"
      >
        <div class="glide__slides">
          <c-media-slider-item
            v-for="(item, index) in items"
            :ref="el => slideItems[index] = el"
            :key="item.id"
            :index="index"
            :total="items.length"
            v-bind="item"
            @video-played="handlePlayed"
          />
        </div>
      </div>
    </div>

    <c-media-slider-controls
      @previous="toPrevious"
      @next="toNext"
    />
  </div>
</template>

<script>
// Third-party
import Glide, { Autoplay, Swipe } from '@glidejs/glide/dist/glide.modular.esm.js'

// Components
import CMediaSliderControls from './CMediaSliderControls.vue'
import CMediaSliderItem from './CMediaSliderItem.vue'

// Globals
import { AUTOPLAY_TIMEOUT } from '../settings.js'

export default {
  name: 'CMediaSlider',

  components: {
    CMediaSliderControls,
    CMediaSliderItem
  },

  props: {
    items: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      slider: null,
      timeout: null,
      userInteraction: false,
      slideItems: []
    }
  },

  mounted () {
    this.slider = new Glide(this.$refs.slider, {
      type: 'carousel',
      gap: 32,
      dragThreshold: false,
      animationDuration: 800,
      animationTimingFunc: 'cubic-bezier(0.4, 0, 0.2, 1)'
    }).mount({ Autoplay, Swipe })

    this.slider.on('run', event => {
      const index = this.slider.index
      const previousIndex = event.direction === '>' ? index - 1 : index + 1
      const isVideo = !!this.items[index].video
      const isPreviousVideo = !!this.items[previousIndex]?.video

      if (isVideo && this.items[index].autoplay && !this.userInteraction) {
        this.stopAutoplay()
        this.slideItems[index].playVideo()
      }

      if (isPreviousVideo && this.userInteraction) {
        this.slideItems[previousIndex].pauseVideo()
      }
    })

    this.slider.on('swipe.end', () => {
      this.setUserInteraction()
    })

    this.startAutoplay()
  },

  beforeUnmount () {
    this.slider.destroy()
  },

  methods: {
    toPrevious () {
      this.userInteraction = true
      this.slider.go('<')
      this.stopAutoplay()
    },
    toNext () {
      this.userInteraction = true
      this.slider.go('>')
      this.stopAutoplay()
    },
    startAutoplay () {
      this.timeout = setInterval(() => {
        this.slider.go('>')
      }, AUTOPLAY_TIMEOUT)
    },
    stopAutoplay () {
      clearInterval(this.timeout)
    },
    setUserInteraction () {
      this.userInteraction = true
      this.stopAutoplay()
    },
    handlePlayed () {
      if (!this.userInteraction) {
        this.startAutoplay()
        this.slider.go('>')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.media-slider {
  position: relative;
  height: 100%;
  padding: 0 5em;
}

// stylelint-disable selector-class-pattern
.glide,
.glide__track,
.glide__slides {
  height: 100%;
}
</style>
