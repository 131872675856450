<template>
  <div class="vimeo">
    <div ref="video" />
  </div>
</template>

<script>
import Player from '@vimeo/player'

export default {
  name: 'CVideoVimeo',

  props: {
    id: {
      type: String,
      required: true
    }
  },

  emits: ['ended'],

  data () {
    return {
      player: null
    }
  },

  mounted () {
    this.player = new Player(this.$refs.video, {
      id: this.id,
      dnt: true,
      color: '0b998f'
    })

    this.player.on('ended', () => {
      this.$emit('ended')
    })
  },

  methods: {
    play () {
      this.player.play()
    },
    pause () {
      this.player.pause()
    }
  }
}
</script>

<style lang="scss" scoped>
.vimeo {
  background: var(--color-mud-700);

  ::v-deep(*) {
    height: 100%;
  }
}
</style>
