<template>
  <c-button
    v-if="isExternal"
    @click="openExternal"
  >
    <slot />
    <c-icon
      type="arrow-right"
      right
    />
  </c-button>

  <c-button
    v-else
    tag="router-link"
    :to="internalLink"
  >
    <slot />
    <c-icon
      type="arrow-right"
      right
    />
  </c-button>
</template>

<script>
// Components
import CButton from './CButton.vue'
import CIcon from './CIcon.vue'

// Globals
import { room } from '../store.js'
import eventBus from '../event-bus.js'

export default {
  name: 'CMediaSliderLink',

  components: {
    CButton,
    CIcon
  },

  props: {
    to: {
      type: String,
      required: true
    }
  },

  computed: {
    internalLink () {
      // Search through all topics and contents until the correct slug has been
      // found. Use that information to construct an internal link based on IDs.

      const slug = this.to
      const topics = room.state.topics
      const roomID = this.$route.params.roomID

      for (const [id, topic] of topics) {
        if (topic.slug === slug) {
          return {
            name: 'topic',
            params: { roomID, topicID: id }
          }
        }

        for (const content of topic.contents) {
          if (content.slug === slug) {
            return {
              name: 'content',
              params: { roomID, topicID: id, contentID: content.id }
            }
          }
        }
      }

      return { name: 'room' }
    },
    isExternal () {
      return this.to.startsWith('https://')
    }
  },

  methods: {
    openExternal () {
      eventBus.emit('external-link-overlay', { open: true, url: this.to })
    }
  }
}
</script>
