<template>
  <c-video-vimeo
    v-if="type === 'vimeo'"
    :id="id"
    ref="video"
  />

  <c-video-you-tube
    v-else-if="type === 'youtube'"
    :id="id"
    ref="video"
  />
</template>

<script>
import CVideoVimeo from './CVideoVimeo.vue'
import CVideoYouTube from './CVideoYouTube.vue'

export default {
  name: 'CVideo',

  components: {
    CVideoVimeo,
    CVideoYouTube
  },

  props: {
    type: {
      type: String,
      required: true,
      validator: value => [
        'vimeo',
        'youtube'
      ].includes(value)
    },
    id: {
      type: String,
      required: true
    }
  },

  methods: {
    play () {
      this.$refs.video.play()
    },
    pause () {
      this.$refs.video.pause()
    }
  }
}
</script>
