<template>
  <c-container>
    <c-container-header>
      <c-title>Einstellungen</c-title>
    </c-container-header>

    <c-container-sidebar />

    <c-container-main class="lobby-main">
      <c-form-switch
        id="foyer"
        v-model="linkToLobby"
        label="Logo verlinkt zur Raumwahl"
      />
    </c-container-main>
  </c-container>
</template>

<script>
// Components
import CContainer from '../components/CContainer.vue'
import CContainerHeader from '../components/CContainerHeader.vue'
import CContainerMain from '../components/CContainerMain.vue'
import CContainerSidebar from '../components/CContainerSidebar.vue'
import CFormSwitch from '../components/CFormSwitch.vue'
import CTitle from '../components/CTitle.vue'

// Globals
import { settings } from '../store.js'

export default {
  name: 'VSettings',

  components: {
    CContainer,
    CContainerHeader,
    CContainerMain,
    CContainerSidebar,
    CFormSwitch,
    CTitle
  },

  computed: {
    linkToLobby: {
      get () {
        return settings.state.linkToLobby
      },
      set (value) {
        settings.set({ linkToLobby: value })
      }
    }
  }
}
</script>
