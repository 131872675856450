<template>
  <div class="room-media">
    <c-media-slider
      v-if="hasMedia"
      :items="media"
    />
  </div>
</template>

<script>
// Components
import CMediaSlider from '../components/CMediaSlider.vue'

// Globals
import { room } from '../store.js'

export default {
  name: 'VRoomMedia',

  components: {
    CMediaSlider
  },

  computed: {
    media () {
      return room.state.meta.media
    },
    hasMedia () {
      return this.media && this.media.length
    }
  }
}
</script>

<style lang="scss" scoped>
.room-media {
  position: relative;
  height: 93.1%;
  top: 3.45%;
}
</style>
