import ky from 'ky'

import { API_BASE } from './settings.js'

const api = ky.create({
  prefixUrl: API_BASE
})

/**
 * Get room list
 */
export function getRoomList () {
  return api.get('rooms').json()
}

/**
 * Get room content
 * @param {number} id Room ID
 */
export function getRoomContent (id) {
  return api.get('', { searchParams: { id } }).json()
}
