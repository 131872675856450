<template>
  <header class="container-header">
    <router-link
      :to="logoLink"
      class="logo-link"
    >
      <img
        src="../assets/logo.svg"
        alt="Online-Wohn-Beratung Logo"
        width="86"
        height="86"
        class="logo"
      >
    </router-link>

    <slot />
  </header>
</template>

<script>
// Globals
import { settings } from '../store.js'

export default {
  name: 'CContainerHeader',

  computed: {
    logoLink () {
      const routeName = this.$route.name
      const linkToLobby = settings.state.linkToLobby

      return ['lobby', 'settings'].includes(routeName) || linkToLobby
        ? { name: 'lobby' }
        : { name: 'room' }
    }
  }
}
</script>

<style lang="scss" scoped>
.container-header {
  grid-area: header;
  display: flex;
  align-items: center;
  padding: 0 var(--container-padding);
  z-index: var(--z-index-header);

  background: var(--color-mud-700);
}

.logo-link {
  height: 100%;
  margin-right: var(--container-padding);

  &:focus {
    outline: 0.3em solid var(--color-blue-100);
  }
}

.logo {
  height: 100%;
  width: auto;
  box-shadow: 0 0.25em 0.5em rgb(0 0 0 / 0.1);
}
</style>
