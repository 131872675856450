<template>
  <transition-group
    name="content-button"
    tag="div"
    appear
  >
    <div
      v-for="(content, index) in contents"
      :key="content.id"
      class="content-button"
      :class="[
        `is-index-${index}`,
        {
          'is-content-nav': isContentNav
        }
      ]"
      :style="{
        '--content-button-index': index
      }"
    >
      <c-nav-button
        :image="content.cover"
        :title="content.name"
        :to="{
          name: 'content',
          params: { roomID, topicID, contentID: content.id }
        }"
        :muted="isContentNav"
      />
    </div>
  </transition-group>
</template>

<script>
// Components
import CNavButton from './CNavButton.vue'

export default {
  name: 'CTopicNav',

  components: {
    CNavButton
  },

  props: {
    contents: {
      type: Array,
      required: true
    }
  },

  computed: {
    isContentNav () {
      return this.$route.name === 'content'
    },
    roomID () {
      return this.$route.params.roomID
    },
    topicID () {
      return this.$route.params.topicID
    }
  }
}
</script>

<style lang="scss" scoped>
.content-button {
  --gap: 10%;
  --x-offset: 4vw;
  --y-offset: 5vh;

  position: absolute;
  top: 0;
  left: 0;
  width: 28%;
  z-index: var(--z-index-content-button);
  transform:
    translateX(calc((100% + var(--gap)) * var(--column-index, 0) + var(--x-offset)))
    translateY(calc((100% + var(--gap) * 1.5) * var(--row-index, 0) + var(--y-offset)));
  transform-origin: top left;

  font-size: 1.5em;

  transition: transform, font-size;
  transition-duration: var(--transition-duration-topic-nav);

  &.is-index-1,
  &.is-index-4,
  &.is-index-7 {
    --column-index: 1;
  }

  &.is-index-2,
  &.is-index-5 {
    --column-index: 2;
  }

  &.is-index-3,
  &.is-index-4,
  &.is-index-5 {
    --row-index: 1;
  }

  &.is-index-6,
  &.is-index-7 {
    --row-index: 2;
  }

  &.is-content-nav {
    transform:
      translateY(calc(100vh - var(--header-height) - var(--container-padding) * 2 - 41%))
      scale(0.41)
      translateX(calc(var(--content-button-index) * (100% + var(--gap))));
    font-size: 2em;
  }
}

.content-button-enter-active {
  transition-property: transform, opacity;
  transition-duration: 0.5s;
  transition-delay: calc(var(--content-button-index) * 0.08s);
}

.content-button-enter-from {
  transform:
    translateX(-15.5vw)
    translateY(calc(var(--topic-button-position, 0) * 1px - 50%))
    scale(0.55);
  opacity: 0.2;
}

.content-button-leave-active {
  transition: opacity 0.5s calc(var(--content-button-index) * 0.04s);
}

.content-button-leave-to {
  opacity: 0;
}
</style>
